@font-face {
  font-family: montReg;
  src: url(../../fonts/Montserrat/static/Montserrat-Regular.ttf);
}
@font-face {
  font-family: montBold;
  src: url(../../fonts/Montserrat/static/Montserrat-Bold.ttf);
}
:root {
  --color-text: whitesmoke;
  --duration: 300ms;
  --ease: cubic-bezier(0.33, 1, 0.68, 1);
  --color-chill: #ecf232;
}

.pageheading {
  color: #ffffff;
  font-family: montReg;
  font-size: 4.6vw;
  letter-spacing: 1px;
  margin-bottom: -1rem;
  scroll-margin-top: 70px;
}

/* .pageheading {
  animation: activate calc(var(--duration) * 2) var(--ease) forwards;

  @keyframes activate {
    25% {
      color: var(--color-chill);
      transform: translateY(-12px);
    }
    40% {
      transform: translateY(2px);
    }
    50% {
      transform: translateY(-4px);
    }
    60% {
      color: var(--color-text);
      transform: translateY(1px);
    }
    65%,
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
} */

@media (min-width: 768px) {
  .pageheading {
    font-size: 4rem;
    margin-bottom: 1rem;
  }
}

@media (min-width: 992px) {
  .pageheading {
    font-family: montBold;
    font-size: 2.4vw;
    text-align: center;
    margin-bottom: 2rem;
  }
  .row {
    margin-top: 1.5rem !important;
    margin-right: 3rem;
    margin-left: 3rem;
  }
}
@media (min-width: 1600px) {
  .row {
    margin-left: 0rem;
    margin-right: 0rem;
    margin-top: 2.5rem !important;
  }
}
@media (min-width: 1900px) {
  .row {
    margin-left: -5rem;
    margin-right: -5rem;
  }
}
/* 
@media screen and (max-width: 1366px) and (max-height: 720px) and (min-height: 300px) {
  .pageheading {
    font-size: 2.4vw;
    text-align: center;
    margin-bottom: -1.5rem;
  }
  .row {
    margin-right: 0px;
    margin-left: 10px;
  }
}

@media screen and (max-width: 1366px) and (max-height: 768px) and (min-height: 721px) {
  .pageheading {
    font-size: 2.4vw;
    text-align: center;
    margin-bottom: -1.5rem;
  }
  .row {
    margin-right: 0px;
    margin-left: 10px;
  }
}
@media (min-width: 1440px) {
  .row {
    width: 80vw;
    margin-right: 0px;
    margin-left: 10px;
  }
}
@media (min-width: 2559px) {
} */
.bgimg {
  z-index: -999;
  width: 100%;
  height: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  object-fit: cover;
  position: absolute;
}
