@font-face {
  font-family: montSemiBold;
  src: url(../../fonts/Montserrat/static/Montserrat-SemiBold.ttf);
}
@font-face {
  font-family: montReg;
  src: url(../../fonts/Montserrat/static/Montserrat-Regular.ttf);
}
.Careercard {
  margin-top: 6rem;
  margin-bottom: 6rem;
  scroll-margin-top: 100px;
}
.heading {
  //padding: 0 1.3rem 0 1.9rem;
  padding-left: 0.6rem;
  padding-right: 0.1rem;
  color: #ffffff;
}
.heading h1 {
  font-family: montReg;
  /* font-size: 1.0546rem; */
  font-size: 4.6vw;
  line-height: 23px;
}
.cardWrapper {
  margin-top: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
@media (min-width: 430px) {
  .heading h1 {
    line-height: 35px;
  }
}

@media (min-width: 768px) {
  .Careercard {
    margin-top: 8rem;
    margin-bottom: 8rem;
  }
  .heading {
    padding: 0 2rem 0 2rem;
  }
  .heading h1 {
    font-size: 4.6vw;
    line-height: 45px;
  }
  .cardWrapper {
    margin-bottom: 4rem;
  }
}

@media (min-width: 992px) {
  .Careercard {
    scroll-margin-top: 170px;
    margin-top: 8rem;
    margin-bottom: 8rem;
  }
  .heading {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0rem;
  }
  .heading h1 {
    font-family: montSemiBold;
    width: 60rem;
    font-size: 1.2vw;
    text-align: center;
    line-height: 25px;
  }
  .cardWrapper {
    margin-top: 1rem;
    flex-direction: row;
  }
}
@media (min-width: 1280px) {
  .Careercard {
    scroll-margin-top: 90px;
  }
  .heading h1 {
    margin-bottom: 3.5rem;
    width: 60rem;
  }
}
@media (min-width: 1600px) {
  .Careercard {
    scroll-margin-top: 150px;
  }
}
@media (min-width: 1700px) {
  .heading h1 {
    line-height: 35px;
    margin-bottom: 5rem;
    width: 79rem;
  }
}
/* @media (min-width: 1440px) {
  .heading h1 {
    width: 70vw;
  }
}
@media (min-width: 1600px) {
  .heading h1 {
    width: 64vw;
  }
}
@media screen and (min-width: 1366px) and (max-height: 720px) and (min-height: 300px) {
  .heading h1 {
    width: 67vw;
  }
}
 */
