.card {
  height: auto;
  width: auto;
  /*  background: transparent linear-gradient(223deg, #000627 0%, #000521 100%) 0%
    0% no-repeat padding-box; */
  position: relative;
  margin-bottom: 1rem;
  transition: all 300ms ease-in-out;
}
.card:active {
  transform: scale(0.7);
}

.card:hover {
  transform: scale(1.1);
  /*   box-shadow: rgb(14 21 47/30%) 0px 45px 100px, rgb(14 21 47/30%) 0px 16px 40px; */
}

.card .smallImg {
  transition: all 300ms ease-in-out;
  width: 8.5vw;
  position: absolute;
  bottom: 2vw;
  left: 1vw;
  border-radius: 50%;
}
.card .bigImg {
  transition: all 300ms ease-in-out;
  width: 43vw;
  aspect-ratio: 1.6/1;
}

@media (min-width: 567px) {
  .card {
    margin-bottom: 1.5rem;
  }
  .card .bigImg {
    width: 35vw;
  }
  .card .smallImg {
    width: 4vw;
  }
}

@media (min-width: 768px) {
  .card {
    margin-bottom: 2rem;
  }
  .card .bigImg {
    width: 28vw;
  }
  .card .smallImg {
    bottom: 1vw;
    width: 4.5vw;
  }
}
@media (min-width: 820px) {
  .card {
    margin-bottom: 1.5rem;
  }
  .card .bigImg {
    width: 27vw;
  }
}
@media (min-width: 884px) {
  .card .bigImg {
    width: 26vw;
  }
}
@media (min-width: 992px) {
  .card {
    margin-bottom: 2rem;
    width: auto;
    height: auto;
    border-radius: 10px;
  }
  .card .bigImg {
    width: 16rem;
    aspect-ratio: 1.6/1;
  }
  .card .smallImg {
    width: 4vw;
    bottom: 1vw;
  }
}
@media (min-width: 1200px) {
  .card {
    margin-bottom: 1.8rem;
  }
  .card .bigImg {
    width: 20rem;
  }
}
@media (min-width: 1400px) {
  .card {
    margin-bottom: 2.2rem;
  }
  .card .bigImg {
    width: 23rem;
  }
}
@media (min-width: 1600px) {
  .card {
    margin-bottom: 2.5rem;
  }
  .card .bigImg {
    width: 25rem;
  }
}
@media (min-width: 1700px) {
  .card {
    margin-bottom: 1.2rem;
  }
  .card .bigImg {
    margin-bottom: 1.8rem;
    width: 25rem;
  }
  .card .smallImg {
    bottom: 2vw;
  }
}
@media (min-width: 1900px) {
  .card .bigImg {
    width: 28rem;
  }
}
/* 
@media screen and (max-width: 1366px) and (max-height: 720px) and (min-height: 300px) {
  .card {
    width: 28rem;
    height: 12rem;
  }
}
@media (min-width: 1440px) {
  .card {
    width: 25rem;
    height: 12rem;
    border-radius: 10px;
  }
}
@media (min-width: 1920px) {
  .card {
    width: 25rem;
    height: 12rem;
    border-radius: 10px;
  }
}
@media (min-width: 2559px) {
  .card {
    width: 105rem;
    height: 18rem;
    border-radius: 18px;
  }
}

@media screen and (min-width: 1280px) and (max-height: 768px) and (min-height: 720px) {
  .card {
    width: 20rem;
    height: 10rem;
  }
}

@media screen and (min-width: 1280px) and (max-height: 800px) and (min-height: 769px) {
  .card {
    width: 20rem;
    height: 10rem;
  }
}

@media screen and (min-width: 1280px) and (max-height: 1024px) and (min-height: 961px) {
  .card {
    width: 20rem;
    height: 10rem;
  }
}
 */
