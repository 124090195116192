@font-face {
  font-family: montReg;
  src: url(../../fonts/Montserrat/static/Montserrat-Regular.ttf);
}
.smoothhide {
  -webkit-transition: all 1s ease-in-out;
  -moz-transition: all 1s ease-in-out;
  -o-transition: all 1s ease-in-out;
  transition: all 1s ease-in-out;
  opacity: 0;
  position: absolute;
  top: -20%;
}
/* .smoothhide:active {
  z-index: -99999;
} */
.smoothshow {
  -webkit-transition: all 1s ease-in-out;
  -moz-transition: all 1s ease-in-out;
  -o-transition: all 1s ease-in-out;
  transition: all 1s ease-in-out;
  opacity: 1;
}
.desktopView {
  display: none;
}
.navMain {
  /*background-color: #B9FF00;  /* #0339d9;*/
  background-color: #ffffff;
}
.nanMainActive {
  opacity: 0.945;
  /*background-color: #B9FF00;  /* #0339d9;*/
  background-color: #ffffff;
}
/* .navMain {
  position: relative;
}
.navMain:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #B9FF00;  /* #0339d9;
  opacity: 0.9;
  z-index: -1;
} */

.bandLogo img {
  /* height: 3rem; */
  width: 4.2rem;    /*2.6rem*/
}
.hemburgerbtn {
  overflow: hidden;
  outline: none !important;
  border: none !important;
  margin-right: -1rem;
  transition: all 0.6s;
  width: 1.6rem;
}
.navbar-toggler:focus {
  outline: none !important;
}
.hamburger-react {
  color: #000000 !important;
}
.list {
  transition: all 0.6ss;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
}
.navItem {
  height: 55vh;
  margin-top: 5rem;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: column;
}
.list li a {
  font-family: Montserrat, Medium;
  font-size: 1.2rem;
  color: #000000;
  font-weight: 500;
}
.socialIcon {
  margin-bottom: 8rem;
  width: 70vw;
  /* display: flex; */
  display: none;    /*removed for Trifino*/
  justify-content: space-around;
  align-items: center;
  flex-direction: row;
}
.icon {
  color: #ffffff;
  background-color: #021059;
  width: 10vw;
  border-radius: 50%;
}

@media (min-width: 576px) {
  .icon {
    margin-top: 2rem;
    width: 6vw;
  }
}
@media (min-width: 768px) {
  .list li a {
    font-size: 2rem;
  }
}

@media (min-width: 992px) {
  .socialIcon,
  .icon {
    display: none;
  }
  .mobileView {
    display: none;
  }
  .desktopView {
    display: flex;
    flex-direction: row;
  }
  .bandLogo {
    width: 3vw;   /*0vw;*/
  }
  .bandLogo img {
    width: 4.5rem;  /*2rem;*/
  }
  .nav_item {
    padding: 0rem;
    width: 100vw;
    height: 4vh !important;
    margin-top: 0rem;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: row;
    list-style: none;
    color: #000000;   /*#ffffff;*/
  }
  .nav_item li a {
    font-family: montReg;
    font-size: 0.8rem !important;
    margin: 0 -0.2rem;
    font-weight: 800;
  }
}

@media (min-width: 1600px) {
  .nav_item {
    justify-content: space-between;
    padding: 0rem;
  }
  .bandLogo img {
    width: 5vw;
  }
}
