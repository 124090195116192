@font-face {
  font-family: montReg;
  src: url(../../fonts/Montserrat/static/Montserrat-Regular.ttf);
}
@font-face {
  font-family: montSemiBold;
  src: url(../../fonts/Montserrat/static/Montserrat-SemiBoldItalic.ttf);
}
.service {
  margin: 4rem 1.3rem 6rem 1.3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  scroll-margin-top: 100px;
}
.pageheading {
  margin-bottom: 3rem;
}
.pageheading h1 {
  /*  font-size: 1.0546rem; */
  font-size: 4.6vw;
  padding-right: 0.5rem;
  line-height: 23px;
  color: #ffffff;
  font-family: montReg;
}

.cardWrapper {
  margin: 0 auto;
  text-align: left;
  width: 100vw;
}
@media (min-width: 430px) {
  .pageheading {
    margin-bottom: 3rem;
    padding-left: 0.5rem;
    padding-right: 1.8rem;
  }
  .pageheading h1 {
    line-height: 35px;
  }
}
@media (min-width: 768px) {
  .service {
    margin: 4rem 2.5rem 6rem 2.5rem;
  }
  .pageheading {
    margin-bottom: 3rem;
    padding-left: 0.5rem;
    padding-right: 1.8rem;
  }
  .pageheading h1 {
    font-size: 4.6vw;
    padding-right: 0.5rem;
    line-height: 45px;
  }
}

@media (min-width: 992px) {
  .pageheading {
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 0rem;
    padding-right: 0rem;
  }
  .pageheading h1 {
    font-family: montSemiBold;
    width: 33rem;
    font-size: 1.2vw;
    text-align: center;
    line-height: 25px;
  }
  .service {
    margin: 4rem 0rem 8rem 0rem;
  }
  .cardWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100vw;
  }
  .firstRow,
  .secondRow {
    width: 58rem;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: row;
  }
  .secondRow {
    margin-top: -0.3rem;
  }
}

@media (min-width: 1280px) {
  .pageheading h1 {
    width: 45rem;
    line-height: 30px;
  }
  .firstRow,
  .secondRow {
    width: 63rem;
  }
  .secondRow {
    margin-top: 0.1rem;
  }
}

@media (min-width: 1600px) {
  .service {
    scroll-margin-top: 130px;
  }
  .pageheading h1 {
    width: 53rem;
  }
  .firstRow,
  .secondRow {
    width: 70rem;
  }
  .secondRow {
    margin-top: 0.4rem;
  }
}
@media (min-width: 1700px) {
  .pageheading h1 {
    width: 57rem;
    line-height: 35px;
  }
  .firstRow,
  .secondRow {
    width: 78rem;
  }
  .secondRow {
    margin-top: 0.4rem;
  }
}
@media (min-width: 1900px) {
  .pageheading h1 {
    width: 65rem;
  }
  .firstRow,
  .secondRow {
    width: 80rem;
  }
  .secondRow {
    margin-top: 0.8rem;
  }
}
@media (min-width: 2500px) {
  .pageheading h1 {
    width: 100rem;
  }
  .firstRow,
  .secondRow {
    width: 105rem;
  }
}
/* @media screen and (min-width: 1366px) and (max-height: 720px) and (min-height: 300px) {
  .pageheading h1 {
    width: 57vw;
  }
  .firstRow,
  .secondRow {
    width: 60vw !important;
  }
  .secondRow {
    margin-top: -3.5vh !important;
  }
}

@media (min-width: 1366px) and (max-height: 768px) and (min-height: 721px) {
  .pageheading h1 {
    width: 72vw;
  }
  .firstRow,
  .secondRow {
    width: 75vw !important;
  }
  .secondRow {
    margin-top: -2.5vh !important;
  }
}

@media screen and (min-width: 1280px) and (max-width: 1365px) and (max-height: 768px) and (min-height: 720px) {
  .pageheading h1 {
    width: 60vw;
  }

  .firstRow,
  .secondRow {
    width: 65vw;
  }
  .secondRow {
    margin-top: -1rem;
  }
}
@media screen and (min-width: 1280px) and (max-height: 800px) and (min-height: 769px) {
  .secondRow {
    margin-top: -0.5rem;
  }
}
@media screen and (min-width: 1280px) and (max-height: 1024px) and (min-height: 961px) {
  .firstRow,
  .secondRow {
    width: 75vw;
  }

  .secondRow {
    margin-top: -1rem;
  }
}

@media (min-width: 1600px) {
  .pageheading h1 {
    width: 62vw;
  }
  .firstRow,
  .secondRow {
    width: 65vw !important;
  }
  .secondRow {
    margin-top: -2.5vh !important;
  }
}
@media (min-width: 2559px) {
  .pageheading h1 {
    line-height: 40px;
  }
  .firstRow {
    margin-bottom: 1rem;
  }
} */
