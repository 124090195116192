@font-face {
  font-family: montBold;
  src: url(../../fonts/Montserrat/static/Montserrat-Bold.ttf);
}
.card {
  width: 80vw;
  margin-bottom: 2rem;
  outline: none !important;
  border: none;
  outline-offset: none !important;
  border-radius: 8px;
  position: relative;
  overflow: hidden;
  background-color: #ffffff;
}
.card img {
  object-fit: none;
}
.overlayText {
  outline-color: #70e2ed;
  font-family: montBold;
  font-weight: bold;
  font-size: 2.5rem;
  line-height: 42px;
  word-spacing: 100vw;
  text-align: center;
  position: absolute;
  text-shadow: 0px 3px 10px #00ffff66;
  top: 15%;
  left: 0%;
  color: #ffffff;
}
.text {
  background-color: #ffffff;
  font-size: 0.95rem;
  font-weight: 300;
  font-family: Poppins, light;
  color: #021059;
  padding: 2rem 1.3rem;
}
.cardactive {
  font-size: 0.95rem;
  font-weight: 300;
  font-family: Poppins, light;
  background-color: #0339d9;
  color: #ffffff;
  padding: 2rem 1.3rem;
}
@media (min-width: 430px) {
  .card {
    width: 80vw;
    margin-bottom: 3rem;
    border-radius: 18px;
  }
  .card img {
    height: 35vh;
    object-fit: cover;
  }
  .overlayText {
    font-size: 3.5rem;
    line-height: 52px;
  }
  .text {
    font-size: 1.25rem;
  }
  .cardactive {
    font-size: 1.25rem;
    background-color: #0339d9;
  }
}

@media (min-width: 576px) {
  .card {
    width: 80vw;
    margin-bottom: 3rem;
    border-radius: 18px;
  }
  .card img {
    height: 65vh;
    object-fit: cover;
  }
  .text {
    padding: 2rem 1rem;
    font-size: 1.2rem;
  }
  .overlayText {
    top: 18%;
  }
  .cardactive {
    padding: 2rem 1rem;
    font-size: 1.2rem;
    word-wrap: normal;
    background-color: #0339d9;
  }
}
@media (min-width: 768px) {
  .card {
    width: 80vw;
    margin-bottom: 3rem;
    border-radius: 28px;
  }
  .card img {
    height: 40vh;
    object-fit: cover;
  }
  .text,
  .cardactive {
    height: 35vh;
    font-size: 1.95rem;
    line-height: 50px;
    padding: 2rem 2rem;
  }

  .overlayText {
    font-size: 5rem;
    line-height: 80px;
    word-spacing: 70px;
    top: 20%;
    left: 0%;
    word-spacing: 100vw;
  }
}

@media (min-width: 992px) {
  .card {
    width: 17rem;
    height: 25rem;
    margin-bottom: 3rem;
    border-radius: 12px;
    margin: 0 0.8rem;
    //animation: TransitioningBackground 10s ease infinite;
    transition: all 0.6s ease-in-out;
  }
  @keyframes TransitioningBackground {
    0% {
      background-position: 1% 0%;
    }
    50% {
      background-position: 99% 100%;
    }
    100% {
      background-position: 1% 0%;
    }
  }

  .card::before {
    content: "";
    display: block;
    position: absolute;
    background: rgba(255, 255, 255, 0.5);
    width: 10px;
    height: 100%;
    top: 0;
    filter: blur(60px);
    transform: translateX(-1000px) skewX(-15deg);
  }
  .card::after {
    content: "";
    display: block;
    position: absolute;
    background: rgba(255, 255, 255, 0.2);
    width: 10px;
    height: 100%;
    top: 0;
    filter: blur(10px);
    transform: translateX(-100px) skewX(-15deg);
  }
  .card:hover {
    //background-image: linear-gradient(to left, #2d8fe5, #d155b8);
    transform: scale(1.01);
    cursor: pointer;
  }
  .card:hover::before,
  .card:hover::after {
    transform: translateX(500px) skewX(-15deg);
    transition: 1.1s;
  }

  .card img {
    height: 55%;
    object-fit: cover;
    //transition: all 2s ease-in-out;
  }
  /*  .card:hover > img {
    transform: scale(1.1);
  } */
  .text,
  .cardactive {
    //transition: all 0.1s ease-in-out;
    font-size: 0.81rem;
    padding: 1.5rem 1rem;
  }
  .text p,
  .cardactive p {
    line-height: 25px;
  }
  .overlayText {
    font-size: 2rem;
    line-height: 30px;
    word-spacing: 70px;
    top: 22%;
    left: 0%;
    word-spacing: 100vw;
  }
}
@media (min-width: 1280px) {
  .card {
    width: 18rem;
  }
  .card img {
    height: 60%;
  }
  .text p,
  .cardactive p {
    line-height: 28px;
  }
}
@media (min-width: 1700px) {
  .card {
    width: 25rem;
    height: 33rem;
    border-radius: 20px;
    margin: 0 1.2rem;
  }
  .card img {
    height: 55%;
  }
  .text,
  .cardactive {
    font-size: 1.01rem;
    line-height: 30px;
    padding: 2rem 1.5rem;
  }
  .text p,
  .cardactive p {
    line-height: 35px;
  }
  .overlayText {
    top: 23%;
    font-size: 2.5rem;
    line-height: 40px;
  }
  .addstroke {
    -webkit-text-stroke: 1px #70e2ed;
  }
}
/* 
@media screen and (min-width: 1280px) and (max-height: 768px) and (min-height: 720px) {
  .card {
    width: 20rem;
    height: 26rem;
  }
  .card img {
    height: 30vh;
  }
  .text {
    font-size: 1rem;
    padding: 1.5rem 1rem 1.5rem 1rem;
  }
  .cardactive {
    font-size: 1rem;
    padding: 1.5rem 1rem 1.5rem 1rem;
  }
}

@media screen and (min-width: 1280px) and (max-height: 800px) and (min-height: 769px) {
  .card {
    width: 20rem;
    height: 26rem;
  }
  .card img {
    height: 30vh;
  }
  .text,
  .cardactive {
    font-size: 0.995rem;
    padding: 1.5rem 1rem 1.5rem 1rem;
  }
}
@media screen and (min-width: 1280px) and (max-height: 1024px) and (min-height: 961px) {
  .card {
    width: 20rem;
    height: 26rem;
  }
  .card img {
    height: 25vh;
  }
  .overlayText {
    top: 25%;
  }
  .text,
  .cardactive {
    font-size: 0.89rem;
    padding: 1.5rem 1rem 1.5rem 1rem;
  }
}

@media screen and (min-width: 1366px) and (max-height: 720px) and (min-height: 300px) {
  .card {
    width: 18rem;
    height: 23rem;
    margin-bottom: 3rem;
    border-radius: 12px;
    margin: 0 1rem;
  }
  .card img {
    height: 30vh;
    object-fit: cover;
  }
  .text,
  .cardactive {
    font-size: 0.9rem;
    padding: 1rem 1rem;
  }

  .overlayText {
    font-size: 2.4rem;
    line-height: 38px;
    top: 22%;
  }
}
@media screen and (min-width: 1366px) and (max-height: 768px) and (min-height: 721px) {
  .card {
    width: 20rem;
    height: 25rem;
    margin-bottom: 3rem;
    border-radius: 12px;
    margin: 0 1rem;
  }
  .card img {
    height: 30vh;
    object-fit: cover;
  }
  .text,
  .cardactive {
    font-size: 0.995rem;
    padding: 1rem 1rem;
  }

  .overlayText {
    font-size: 2.4rem;
    line-height: 38px;
    top: 22%;
  }
}
@media (min-width: 1440px) {
  .card {
    width: 20rem;
    height: 30rem;
  }
  .card img {
    height: 30vh;
  }
  .overlayText {
    font-size: 3vw;
    line-height: 40px;
  }
  .text,
  .cardactive {
    font-size: 1.115rem;
    padding: 1rem 1rem;
  }
}
@media (min-width: 1920px) {
  .card {
    width: 25rem;
    height: 33rem;
  }
  .card img {
    height: 28vh;
  }
  .overlayText {
    top: 20%;
    font-size: 3vw;
    line-height: 50px;
  }
  .text,
  .cardactive {
    font-size: 1.215rem;
    padding: 1rem 1rem;
  }
}
@media (min-width: 2559px) {
  .card {
    width: 33rem;
    height: 42rem;
    border-radius: 20px;
  }
  .overlayText {
    line-height: 65px;
  }
  .text,
  .cardactive {
    font-size: 1.68rem;
  }
}
 */
