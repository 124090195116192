.footerwrapper {
  background-color: #000624 !important;
  opacity: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.socialIcons {
  margin-top: 1rem;
  width: 70vw;
  /* display: flex; */
  display: none;    /*removed for Trifino*/
  justify-content: space-around;
  align-items: center;
  flex-direction: row;
  margin-bottom: 1rem;
}
.icon {
  color: #ffffff;
  background-color: #021059;
  width: 10vw;
  border-radius: 50%;
}
.content {
  padding-top: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.yearReserved {
  margin-bottom: 0.2rem;
}
.content p {
  font-family: Montserrat, Light;
  font-size: 0.8rem;
  color: #ffffff;
}
.ScrollToTop {
  height: 10vw;
}
@media (min-width: 576px) {
  .icon {
    color: #ffffff;
    background-color: #021059;
    width: 6vw;
    border-radius: 50%;
  }
}
@media (min-width: 768px) {
  .socialIcons {
    margin-top: 1.5rem;
  }
  .content {
    padding-top: 1.5rem;
  }
  .content p {
    font-size: 1.5rem;
  }
}
@media (min-width: 992px) {
  .footerwrapper {
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: row-reverse;
    padding: 1rem;
  }
  .content {
    width: 40vw;
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: row-reverse;
    padding-top: 0rem;
  }
  .content p {
    font-size: 0.5rem;
    margin-bottom: 0rem;
  }
  .yearReserved {
    margin-bottom: 0rem;
  }
  .socialIcons {
    margin-top: 0rem;
    margin-bottom: 0rem;
    width: 20vw;
    justify-content: space-evenly;
  }
  .icon {
    color: #ffffff;
    background-color: #021059;
    width: 2vw;
    border-radius: 50%;
  }
}

@media (min-width: 1366px) {
  .content p {
    font-size: 0.8rem;
    margin-bottom: 0rem;
  }
}
