@font-face {
  font-family: poppreg;
  src: url(../../fonts/Poppins-Regular.ttf);
}
@font-face {
  font-family: montbold;
  src: url(../../fonts/Montserrat/static/Montserrat-Bold.ttf);
}
.animateDiv {
  transform-style: preserve-3d;
  transition: all 1s;
  transition: box-shadow 0.5s;
  will-change: transform;
  box-shadow: rgb(14 21 47 / 60%) 0px 8px 30px;
  margin-bottom: 1.5rem;
}
.animateDiv:hover {
  transform: all 1s;
  box-shadow: rgb(14 21 47 / 40%) 0px 45px 100px,
    rgb(14 21 47 / 40%) 0px 16px 40px;
  transform: scale3d(1.07, 1.07, 1.07);
}

.card {
  position: relative;
  color: #ffffff;
  padding: 2rem 0.5rem 1.5rem 1.5rem;
  border-radius: 10px;
  box-shadow: 0px 10px 30px -5px rgba(0, 0, 0, 0.3);
  will-change: transform;
  transform-style: preserve-3d;
}
:root {
  --color1: rgb(0, 231, 255);
  --color2: rgb(255, 0, 231);
}

.animCard {
  animation: holoCard 12s ease 0s 1;
  position: relative;
  z-index: 10;
  box-shadow: -5px -5px 5px -5px var(--color1), 5px 5px 5px -5px var(--color2),
    -7px -7px 10px -5px transparent, 7px 7px 10px -5px transparent,
    0 0 5px 0px rgba(255, 255, 255, 0), 0 55px 35px -20px rgba(0, 0, 0, 0.5);
  transition: transform 0.5s ease, box-shadow 0.2s ease;
  will-change: transform, filter;
}

@keyframes holoSparkle {
  0%,
  100% {
    opacity: 0.75;
    background-position: 50% 50%;
    filter: brightness(1.2) contrast(1.25);
  }
  5%,
  8% {
    opacity: 1;
    background-position: 40% 40%;
    filter: brightness(0.8) contrast(1.2);
  }
  13%,
  16% {
    opacity: 0.5;
    background-position: 50% 50%;
    filter: brightness(1.2) contrast(0.8);
  }
  35%,
  38% {
    opacity: 1;
    background-position: 60% 60%;
    filter: brightness(1) contrast(1);
  }
  55% {
    opacity: 0.33;
    background-position: 45% 45%;
    filter: brightness(1.2) contrast(1.25);
  }
}

@keyframes holoGradient {
  0%,
  100% {
    opacity: 0.5;
    background-position: 50% 50%;
    filter: brightness(0.5) contrast(1);
  }
  5%,
  9% {
    background-position: 100% 100%;
    opacity: 1;
    filter: brightness(0.75) contrast(1.25);
  }
  13%,
  17% {
    background-position: 0% 0%;
    opacity: 0.88;
  }
  35%,
  39% {
    background-position: 100% 100%;
    opacity: 1;
    filter: brightness(0.5) contrast(1);
  }
  55% {
    background-position: 0% 0%;
    opacity: 1;
    filter: brightness(0.75) contrast(1.25);
  }
}

@keyframes holoCard {
  0%,
  100% {
    transform: rotateZ(0deg) rotateX(0deg) rotateY(0deg);
  }
  5%,
  8% {
    transform: rotateZ(0deg) rotateX(6deg) rotateY(-20deg);
  }
  13%,
  16% {
    transform: rotateZ(0deg) rotateX(-9deg) rotateY(32deg);
  }
  35%,
  38% {
    transform: rotateZ(3deg) rotateX(12deg) rotateY(20deg);
  }
  55% {
    transform: rotateZ(-3deg) rotateX(-12deg) rotateY(-27deg);
  }
}

.animCard:before,
.animCard:after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background-image: linear-gradient(
    115deg,
    transparent 0%,
    rgb(0, 231, 255) 30%,
    rgb(255, 0, 231) 70%,
    transparent 100%
  );
  background-position: 0% 0%;
  background-repeat: no-repeat;
  background-size: 300% 300%;
  opacity: 0.2;
  z-index: 1;
  animation: holoGradient 15s ease;
}

/* 
.card:after {
  content: "";
  position: absolute;
  top: 10%;
  left: 10%;
  width: 20%;
  height: 10%;
  opacity: 0;
  transform: rotate(30deg);

  background: rgba(255, 255, 255, 0.13);
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0.13) 0%,
    rgba(255, 255, 255, 0.13) 77%,
    rgba(255, 255, 255, 0.5) 92%,
    rgba(255, 255, 255, 0) 100%
  );
}

.card:hover:after {
  opacity: 1;
  left: 130%;
  transition-property: left, top, opacity;
  transition-duration: 0.7s, 0.7s, 0.15s;
  transition-timing-function: ease;
}


.card:active:after {
  opacity: 0;
}
 */
.card h1 {
  font-family: montbold;
  font-weight: bold;
  font-size: 1.1rem;
  color: #d6ff00;
  text-align: start;
  margin-bottom: 1.5rem;
}
.card li {
  font-family: poppreg;
  font-size: 3vw;
  line-height: 20px;
  padding-left: -3px !important;
}

@media (min-width: 430px) {
  .card h1 {
    font-size: 1.9rem;
    margin-bottom: 1.8rem;
  }
  .card li {
    font-size: 2.8vw;
    line-height: 30px;
    padding-left: -8px !important;
  }
}
@media (min-width: 768px) {
  .card {
    padding: 3rem 1.5rem 2.2rem 1.5rem;
    border-radius: 10px;
  }
  .card h1 {
    font-size: 2.1rem;
    margin-bottom: 1.8rem;
  }
  .card li {
    font-size: 3.8vw;
    line-height: 50px;
    padding-left: -8px !important;
  }
}
@media (min-width: 992px) {
  .card {
    /* width: 15rem; */
    padding: 1.2rem 0.1rem 1rem 1.5rem;
    border-radius: 10px;
  }
  .card h1 {
    font-size: 1.2vw;
    margin-bottom: 1rem;
  }
  .card li {
    font-size: 0.9vw;
    line-height: 20px;
  }
}
@media (min-width: 1280px) {
  /*   .card {
    width: 18rem;
  } */
  .card li {
    line-height: 25px;
  }
}
@media (min-width: 1600px) {
  /*   .card {
    width: 20rem;
  } */
}
@media (min-width: 1700px) {
  .card {
    padding: 1.2rem 2rem;
  }
  .card li {
    line-height: 30px;
    font-size: 0.8vw;
  }
}
@media (min-width: 1950px) {
  .card {
    padding-left: 1.5rem;
    padding-right: 0.5rem;
  }
}
/* @media (min-width: 1366px) and (max-height: 720px) and (min-height: 300px) {
  .card {
    width: 16rem;
  }
  .card li {
    font-size: 0.8vw;
  }
}

@media (min-width: 1366px) and (max-height: 768px) and (min-height: 721px) {
  .card {
    width: 15rem;
    padding: 1.2rem 0.1rem 1rem 1rem;
    border-radius: 5px;
  }
  .card h1 {
    font-size: 0.89rem;
    margin-bottom: 1rem;
  }
  .card li {
    font-size: 0.9vw;
    line-height: 20px;
  }
}
@media (min-width: 1440px) {
  .card {
    width: 20rem;
    padding: 1.2rem 0.1rem 1rem 1rem;
    border-radius: 5px;
  }
  .card h1 {
    font-size: 1.29rem;
    margin-bottom: 1rem;
  }
  .card li {
    font-size: 1.01vw;
    line-height: 25px;
  }
}
@media screen and (min-width: 1280px) and (max-height: 768px) and (min-height: 721px) {
  .card {
    width: 20rem;
    padding: 1.2rem 0.1rem 0.9rem 1rem;
    border-radius: 5px;
  }

  .card h1 {
    font-size: 1.2rem;
    margin-bottom: 1rem;
  }
  .card li {
    font-size: 1.2vw;
    line-height: 30px;
  }
}
@media screen and (min-width: 1280px) and (max-height: 800px) and (min-height: 769px) {
  .card {
    width: 18rem;
    padding: 1.2rem 0.1rem 0.9rem 1rem;
    border-radius: 5px;
  }
  .card h1 {
    font-size: 1.2rem;
    margin-bottom: 1rem;
  }
  .card li {
    font-size: 1vw;
    line-height: 25px;
  }
}

@media screen and (min-width: 1280px) and (max-height: 1024px) and (min-height: 961px) {
  .card {
    width: 18rem;
    border-radius: 5px;
  }
  .card h1 {
    font-size: 1.2rem;
    margin-bottom: 1rem;
  }
  .card li {
    font-size: 1vw;
    line-height: 25px;
  }
}

@media (min-width: 1920px) {
  .card {
    width: 25rem;
  }
  .card li {
    line-height: 35px;
  }
} */

@media (min-width: 2559px) {
  .card {
    width: 33rem;
  }
  .card li {
    line-height: 40px;
  }
}
