@font-face {
  font-family: boldFont;
  src: url(../fonts/Montserrat/static/Montserrat-Bold.ttf);
}
@font-face {
  font-family: popLight;
  src: url(../fonts/Poppins-Light.ttf);
}
@font-face {
  font-family: popMed;
  src: url(../fonts/Poppins-Medium.ttf);
}
@font-face {
  font-family: montExtra;
  src: url(../fonts/Montserrat/static/Montserrat-Bold.ttf);
}

.careerWrapper {
  height: 100vh;
  overflow: hidden;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.careerWrapper img {
  /*   background-image: url(../images/cmpcareer/Background.jpg); */
  height: 100vh;
  width: 100vw;
  object-fit: fill;
  position: absolute;
  z-index: -1;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  /*  background-repeat: no-repeat;
  background-position: center;
  object-fit: cover;
  background-size: cover; */
}
.textWrapper {
  margin-top: 2rem;
  padding: 4rem;
  color: #ffffff;
}
.textWrapper h1 {
  font-family: boldFont;
  font-size: 9vw;
}
.textWrapper p,
.textWrapper li {
  font-size: 3vw;
  font-family: popLight;
  line-height: 25px;
}
.textWrapper ul {
  padding-left: 1rem;
}
.btnWrapper {
  width: 80vw;
  height: 15vh;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
}
.btnWrapper form {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.fileInput {
  display: none;
}

label[for="file-input"] {
  margin-bottom: 2rem;
  padding: 0.9em 6.5em;
  width: 80vw;
  font-family: popMed;
  text-align: center;
  cursor: pointer;
  font-size: 3vw;
  color: #0339d9;
  box-shadow: 0px 10px 6px #00000029;
  border-radius: 12px;
  background-color: #ffffff;
  animation: all 0.6s;
}
label[for="file-input"]:hover {
  background-color: #f7f7f7;
}

.btnWrapper .applybtn {
  padding: 0.7em 2em;
  color: #0339d9;
  background: #d6ff00;
  cursor: pointer;
  outline: none !important;
  border-radius: 38px;
  border: none !important;
  font-family: montExtra;
  font-size: 4vw;
  margin-left: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
  transition: all 0.6s;
}

@keyframes gradient {
  0% {
    background: radial-gradient(
      circle at center,
      rgba(255, 125, 125, 0) 0%,
      #fff 0%,
      #fff 100%
    );
  }
  25% {
    background: radial-gradient(
      circle at center,
      rgba(104, 154, 239, 0.3) 24%,
      #fff 25%,
      #fff 100%
    );
  }
  /* 50% {
    background: radial-gradient(
      circle at center,
 rgba(104, 154, 239, 0.3)  49%,
      #fff 50%,
      #fff 100%
    );
  } */
  75% {
    background: radial-gradient(
      circle at center,
      rgba(104, 154, 239, 0.3) 74%,
      #fff 75%,
      #fff 100%
    );
  }
  100% {
    color: #fff;
    background: radial-gradient(
      circle at center,
      rgb(125, 146, 228) 99%,
      #fff 100%,
      #fff 100%
    );
  }
}

.applybtn:active {
  color: #fff;
  /* set time duration to your needs */
  animation: gradient 400ms;
  transition: all 0.6s;
  background: rgb(132, 141, 233);
  color: #fff;
  box-shadow: none;
}
/* .applybtn:before {
  content: "";
  position: absolute;
  z-index: -1;
  background: #3a0ca3;
  height: 150px;
  width: 200px;
  border-radius: 50%;
} */

.applybtn:hover {
  transform: scale(1.07);
}

/* .applybtn:before {
  top: 100%;
  left: 100%;
  transition: all 0.7s;
}

.applybtn:hover:before {
  top: -30px;
  left: -30px;
}

.applybtn:active:before {
  background: #3a0ca3;
  transition: background 0s;
} */

.backBtn {
  position: absolute;
  top: 2.5%;
  right: 2.5%;
  background: #0339d9;
  padding: 0.2em 1em;
  cursor: pointer;
  border: 3.5px solid #ffffff;
  border-radius: 15px;
  font-family: boldFont;
  font-size: 4vw;
  color: #ffffff;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.6s;
}
.backBtn:hover {
  transform: scale(1.07);
}
.backBtn .sideArrow {
  font-weight: bolder;
  font-family: boldFont;
  margin-right: 0rem;
  font-size: 2rem;
}
@media (min-width: 768px) {
  .textWrapper {
    margin-top: 4rem;
    padding: 5rem;
  }
  .textWrapper p,
  .textWrapper li {
    font-size: 3vw;
    line-height: 35px;
  }
  .applybtn,
  label[for="file-input"] {
    margin-top: 2rem;
  }
  .btnWrapper {
    height: 18vh;
  }
  .backBtn {
    font-size: 3vw;
  }
  .backBtn .sideArrow {
    margin-right: 0.3rem;
  }
  .btnWrapper .applybtn {
    font-size: 3vw;
    padding: 1.5em 2em;
  }
}
@media (min-width: 992px) {
  .careerWrapper {
    align-items: flex-start;
    padding-left: 10rem;
  }
  .textWrapper h1 {
    font-size: 4vw;
  }
  .textWrapper p,
  .textWrapper li {
    font-size: 1vw;
    line-height: 30px;
  }
  .btnWrapper {
    width: 50vw;
    flex-direction: row;
    justify-content: flex-start;
  }
  .btnWrapper form {
    flex-direction: row;
  }
  label[for="file-input"] {
    margin-top: 0.1rem;
    margin-bottom: 0rem;
    width: 30vw;
    font-size: 0.98vw;
  }
  .btnWrapper .applybtn {
    margin-top: 0.1rem;
    font-size: 0.98vw;
    padding: 0.7em 1.5em;
  }
  .backBtn {
    top: 3%;
    right: 2%;
    padding: 0.5em 2.5em 0.5em 2em;
    font-size: 0.8vw;
    border-radius: 12px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .backBtn .sideArrow {
    margin-right: 0rem;
    font-size: 1.5rem;
    text-align: center;
  }
}
@media (min-width: 1400px) {
  .textWrapper p,
  .textWrapper li {
    font-size: 1vw;
    line-height: 45px;
  }
  .backBtn {
    border: 4.5px solid #ffffff;
    font-size: 1vw;
    border-radius: 15px;
  }
  .backBtn .sideArrow {
    font-size: 3rem;
  }
}
@media (min-width: 1900px) {
  .careerWrapper {
    padding-left: 16rem;
  }
  .backBtn {
    padding: 0.3em 2.5em 0.3em 2em;
  }
}
@media (min-width: 2500px) {
  .careerWrapper {
    align-items: flex-start;
  }
  .textWrapper {
    margin-left: 15rem;
  }
}
