.cardMain {
  background-color: white;
  border-radius: 10px;
  width: 28vw;
  display: flex;
  justify-content: center;
  align-items: center;
}
.cardmageWrapper img {
  width: 28vw;
}
@media (min-width: 768px) {
  .cardMain {
    background-color: white;
    border-radius: 10px;
    width: 22vw;
  }
  .cardmageWrapper img {
    width: 22vw;
  }
}
