@font-face {
  font-family: montExtra;
  src: url(../../fonts/Montserrat/static/Montserrat-ExtraBold.ttf);
}
@font-face {
  font-family: montBold;
  src: url(../../fonts/Montserrat/static/Montserrat-Bold.ttf);
}
@font-face {
  font-family: montReg;
  src: url(../../fonts/Montserrat/static/Montserrat-Regular.ttf);
}
.formConatiner {
  background: transparent linear-gradient(323deg, #8521ff 0%, #00ffff 100%) 0%
    0% no-repeat padding-box;
  padding: 1rem 2rem 1rem 2rem;
  margin: 2rem;
  border-radius: 20px;
}
.formConatiner form {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.formConatiner form input,
.PurposeInput {
  background-color: #021059;
  text-align: center;
  opacity: 0.56;
  border: none;
  font-family: Poppins, Regular;
}
.formConatiner form input::placeholder {
  color: #a6aed8;
  opacity: 1;
}
.disabledtag {
  display: none;
}
.PhoneInput {
  border-radius: 12px;
  background-color: #1851a2 !important;
  height: 3rem !important;
  width: 70vw !important;
  opacity: 0.56;
  padding: 0 !important;
  font-family: Poppins, Regular !important;
  font-size: 1.1vw;
}
.PhoneContainer {
  padding: 0;
  background-color: transparent !important;
  outline: none !important;
  border: none !important;
}
.PhoneInput::placeholder {
  color: #a6aed8 !important;
  opacity: 1 !important;
}
.PhoneInput:focus {
  opacity: 0.56 !important;
}
.NameInput,
.EmailInput,
.PhoneInput,
.PurposeInput {
  background-color: #021059;
  opacity: 0.56;
  width: 70vw;
  height: 3rem;
  color: #a6aed8;
  text-align: center !important;
  cursor: pointer;
}
.NameInput:focus,
.EmailInput:focus,
.PurposeInput:focus {
  background-color: #8be6fe !important;
}

.PurposeInput option {
  cursor: pointer;
  font-family: Poppins, Regular !important;
  background-color: #021059 !important;
  height: 50rem !important;
  text-align: center;
}

.PurposeInput {
  text-align: center;
  font-family: Poppins, Regular !important;
  color: #a6aed8;
  position: relative;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
.optiontags {
  color: #ffffff;
  font-family: Poppins, Regular !important;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.78rem;
  height: 20rem !important;
}
.dropdownicon {
  font-size: 2.5rem;
  position: absolute;
  right: 1%;
  color: #a6aed8;
  top: 5%;
}

.MessageInput {
  width: 70vw;
  cursor: pointer;
  height: 5rem;
  color: #a6aed8;
  opacity: 0.56;
}
/* .coffee_btn:hover {
  background-color: #fff !important;
  color: blue !important  ;
} */
.coffee_btn {
  position: relative;
  transition: all 0.6s;
  font-family: montReg;
  padding: 0.9rem 0.89rem;
  width: 60vw;
  font-size: 4vw;
  background-color: #ffffff;
  color: #0339d9;
  text-align: center;
  border-radius: 50px;
  font-weight: 600;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@keyframes gradient {
  0% {
    background: radial-gradient(
      circle at center,
      rgba(255, 125, 125, 0) 0%,
      #fff 0%,
      #fff 100%
    );
  }
  25% {
    background: radial-gradient(
      circle at center,
      rgba(104, 154, 239, 0.3) 24%,
      #fff 25%,
      #fff 100%
    );
  }
  /* 50% {
    background: radial-gradient(
      circle at center,
 rgba(104, 154, 239, 0.3)  49%,
      #fff 50%,
      #fff 100%
    );
  } */
  75% {
    background: radial-gradient(
      circle at center,
      rgba(104, 154, 239, 0.3) 74%,
      #fff 75%,
      #fff 100%
    );
  }
  100% {
    color: #fff;
    background: radial-gradient(
      circle at center,
      rgb(125, 146, 228) 99%,
      #fff 100%,
      #fff 100%
    );
  }
}

.coffee_btn:active {
  /* set time duration to your needs */
  animation: gradient 400ms;
  transition: all 0.6s;
  background: rgb(132, 141, 233);
  color: #fff;
  box-shadow: none;
}

@media (min-width: 768px) {
  .formConatiner {
    padding: 2rem 2.5rem;
    margin: 3rem;
  }
  .PhoneInput {
    height: 5rem !important;
    width: 70vw !important;
    border-radius: 12px !important;
    font-size: 1.5rem !important;
  }
  .NameInput,
  .EmailInput,
  .PhoneInput,
  .PurposeInput {
    border-radius: 15px;
    width: 70vw;
    height: 5rem;
    font-size: 1.5rem;
    margin-bottom: 0.8rem;
  }
  .MessageInput {
    border-radius: 15px;
    font-size: 1.5rem;
    height: 8rem;
  }
  .dropdownicon {
    font-size: 2.5rem;
    position: absolute;
    right: 3%;
    color: #a6aed8;
    top: 20%;
  }
  .coffee_btn {
    margin: 1rem auto;
  }
}
@media (min-width: 992px) {
  .formConatiner {
    width: 30vw;
    border-radius: 30px;
    padding: 2rem 0.2rem;
    margin: 0rem 1rem 0rem 0rem;
  }
  .PhoneInput {
    width: 25vw !important;
    border-radius: 12px;
    height: 3vw !important;
    font-size: 1.1vw !important;
  }
  .NameInput,
  .EmailInput,
  .PhoneInput,
  .PurposeInput {
    border-radius: 12px;
    width: 25vw;
    height: 3vw;
    font-size: 1.1vw;
    margin-bottom: 0.2rem;
  }
  .MessageInput {
    font-size: 1.1vw;
    width: 25vw;
    border-radius: 12px;
    height: 7vw;
  }
  .coffee_btn {
    margin: 0rem auto;
    width: 17vw;
    padding: 0.7rem 0.4rem;
    font-size: 1.2vw;
    font-family: montBold;
  }
  .optiontags {
    font-size: 1.1vw;
  }
  .dropdownicon {
    font-size: 2rem;
    position: absolute;
    right: 2%;
    color: #a6aed8;
    top: -1%;
  }
}

@media (min-width: 1280px) {
  .formConatiner {
    margin: 0rem;
  }
  .dropdownicon {
    right: 2%;
    top: 10%;
  }
}
@media (min-width: 1366px) {
  .formConatiner {
    margin-left: -4rem;
  }
}
@media (min-width: 1800px) {
  .formConatiner {
    margin-left: 13rem;
  }

  .NameInput,
  .EmailInput,
  .MessageInput,
  .PhoneInput,
  .PurposeInput {
    margin: 0.4rem 0rem;
  }
  .dropdownicon {
    font-size: 3rem;
    position: absolute;
    right: 2%;
    color: #a6aed8;
    top: 15%;
  }
}
/* @media (min-width: 2559px) {

   */
.coffee_btn img {
  width: 2vw;
  animation: 2s slide-right !important;
}

@keyframes slide-right {
  from {
    margin-left: 100%;
  }
  to {
    margin-right: 0%;
  }
}
