@import url(https://fonts.googleapis.com/css?family=Montserrat:100,200,300,regular,500,600,700,800,900,100italic,200italic,300italic,italic,500italic,600italic,700italic,800italic,900italic);
@import url(https://fonts.googleapis.com/css?family=Poppins:100,100italic,200,200italic,300,300italic,regular,italic,500,500italic,600,600italic,700,700italic,800,800italic,900,900italic);
body {
  scroll-behavior: smooth;
  margin: 0;
  overflow-x: hidden;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
@font-face {
  font-family: montEXBold;
  src: url(./fonts/Montserrat/static/Montserrat-ExtraBold.ttf);
}
@font-face {
  font-family: semiBold;
  src: url(./fonts/Montserrat/static/Montserrat-SemiBold.ttf);
}
/* background animation */
.bgsetaniamtion {
  background-image: linear-gradient(
    -70deg,
    #000627 35%,
    #8521ff 40%,
    #00ffff 45%,
    #021059 60%
  ) !important;
  height: 200vh !important;
}
.bg {
  background-color: #021059;
  background-repeat: repeat-y;
  animation: slide 3s ease-in-out infinite alternate;
  background-image: linear-gradient(
    -70deg,
    #000627 35%,
    #8521ff 40%,
    #00ffff 45%,
    #021059 60%
  );
  height: 100%;
  left: -50%;
  right: -50%;
  opacity: 0.5;
  bottom: 0;
  top: 0;
  position: absolute;
  z-index: -1;
}
.bg2 {
  animation-direction: alternate-reverse;
  animation-duration: 5s;
}
.bg3 {
  animation-duration: 8s;
}
/* cardpage bg img */
.cardBgimg {
  object-fit: cover;
  z-index: -999;
  width: 100%;
  height: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  position: absolute;
}

#caseStudies {
  scroll-margin-top: 100px;
}

@keyframes slide {
  0% {
    transform: translateX(-25%);
  }
  100% {
    transform: translateX(25%);
  }
}
.main_body_container {
  position: relative;
  overflow-x: hidden;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* nav bar link item */
.aboutSec {
  color: #d6ff00 !important;
  font-family: Montserrat, Medium !important;
}

/* navbar */
.navbar-nav {
  height: 100vh !important;
}
.hamburger-react {
  color: black;
}
.hamburger-react div {
  transition: all 0.6s cubic-bezier(0, 0, 0, 1) 0s !important;
  width: 30px !important;   /*42px*/
  height: 3.2px !important;   /*4.2px*/
  border-radius: 50px !important;
}
.hamburger-react div .navbar-collapse {
  transition: all 0.6s cubic-bezier(0, 0, 0, 1) 0s !important;
}

/* services cards */

.firstcard,
.thirdcard,
.fivethcard {
  background: transparent
    linear-gradient(133deg, #00ffff 0%, #399fff 21%, #8324ff 100%) 0% 0%
    no-repeat padding-box;

  height: auto;
  width: 80vw;
}
.secondcard,
.fourthcard,
.sixthcard {
  width: 80vw;
  background: transparent linear-gradient(130deg, #8521ff 0%, #0339d9 100%) 0%
    0% no-repeat padding-box;
  border-radius: 5px;
}
.parallax-card {
  border-radius: 12px !important;
}
.parallax-card-container {
  border-radius: 12px !important;
}
.parallax-card-shadow {
  border-radius: 12px !important;
}
.parallax-card-shine {
  border-radius: 12px !important;
}
.parallax-card-layers {
  margin: 0 0 1.5rem 0;
}
@media (min-width: 768px) {
  .scroll-to-top {
    right: 1vw !important;
  }
  .firstcard,
  .thirdcard,
  .fivethcard {
    height: auto;
    width: 80vw;
  }
  .secondcard,
  .fourthcard,
  .sixthcard {
    width: 80vw;
    background: transparent linear-gradient(130deg, #8521ff 0%, #0339d9 100%) 0%
      0% no-repeat padding-box;
    border-radius: 5px;
  }
  .parallax-card-layers {
    margin: 0 0 2.5rem 0;
  }
}
/* @media (min-width: 768px) {
  .navbar-nav {
    height: auto !important;
  }
} */

/* career active card */
.active {
  color: #ffffff !important;
  background-color: #0339d9;
}

/* scroll to top */
.scroll-to-top {
  background-color: transparent !important;
  z-index: 2 !important;
  height: 4rem !important;
  width: 4rem !important;
  right: 2% !important;
  bottom: 2% !important;
  border-radius: 50% !important;
  /* background-color: #0339d9 !important;*/
  background-image: url(./images/scrolltotop.svg);
  object-fit: contain;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  box-shadow: none !important;
}

/*submission popup of career page*/
.swal-title {
  color: #0339d9;
  font-family: semiBold;
}
.swal-text {
  color: #0339d9;
  font-family: semiBold;
}
.swal-button {
  font-family: semiBold;
  background-color: #0339d9;
}

/* text animation */
.textAnim {
  //transform-origin: 50% 100%;
  animation: activate 3s ease-in-out !important;
}

@keyframes activate {
  25% {
    color: #d6ff00;
    transform: translateY(-12px);
  }
  40% {
    transform: translateY(2px);
  }
  50% {
    transform: translateY(-4px);
  }
  60% {
    color: var(--color-text);
    transform: translateY(1px);
  }
  65%,
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

/* animation */

/* .card:before,
.card:after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background-repeat: no-repeat;
  opacity: 0.5;
  mix-blend-mode: color-dodge;
  transition: all 0.33s ease;
} */

/* .card:before {
  background-position: 50% 50%;
  background-size: 300% 300%;
  background-image: linear-gradient(
    115deg,
    transparent 0%,
    var(--color1) 25%,
    transparent 47%,
    transparent 53%,
    var(--color2) 75%,
    transparent 100%
  );
  opacity: 0.5;
  filter: brightness(0.5) contrast(1);
  z-index: 1;
} */

/* .card:after {
  opacity: 1;
  background-image: url("https://assets.codepen.io/13471/sparkles.gif"),
    url(https://assets.codepen.io/13471/holo.png),
    linear-gradient(
      125deg,
      #ff008450 15%,
      #fca40040 30%,
      #ffff0030 40%,
      #00ff8a20 60%,
      #00cfff40 70%,
      #cc4cfa50 85%
    );
  background-position: 50% 50%;
  background-size: 160%;
  background-blend-mode: overlay;
  z-index: 2;
  filter: brightness(1) contrast(1);
  transition: all 0.33s ease;
  mix-blend-mode: color-dodge;
  opacity: 0.75;
} */
@media (min-width: 992px) {
  .hamburger-react {
    display: none;
  }
  .firstcard,
  .thirdcard {
    background: transparent
      linear-gradient(135deg, #00ffff 0%, #399fff 26%, #8324ff 100%) 0% 0%
      no-repeat padding-box;
  }
  .fivethcard {
    background: transparent
      linear-gradient(113deg, #00ffff 0%, #399fff 24%, #8324ff 100%) 0% 0%
      no-repeat padding-box;
  }
  .secondcard,
  .fourthcard,
  .sixthcard {
    background: transparent linear-gradient(117deg, #8521ff 0%, #0339d9 100%) 0%
      0% no-repeat padding-box;
  }
  .firstcard,
  .secondcard,
  .thirdcard {
    height: 16rem;
  }
  .fourthcard,
  .fivethcard,
  .sixthcard {
    height: 8rem;
  }
  .firstcard,
  .thirdcard,
  .fourthcard,
  .sixthcard {
    width: 17rem;
  }
  .secondcard,
  .fivethcard {
    width: 19rem;
  }
}
@media (min-width: 1280px) {
  .firstcard,
  .secondcard,
  .thirdcard {
    height: 19rem;
  }
  .fourthcard,
  .fivethcard,
  .sixthcard {
    height: 10rem;
  }
  .firstcard,
  .thirdcard,
  .fourthcard,
  .sixthcard {
    width: 18rem;
  }
  .secondcard,
  .fivethcard {
    width: 21rem;
  }
}
@media (min-width: 1600px) {
  .firstcard,
  .thirdcard,
  .fourthcard,
  .sixthcard {
    width: 20rem;
  }
  .secondcard,
  .fivethcard {
    width: 23rem;
  }
}
@media (min-width: 1700px) {
  .firstcard,
  .secondcard,
  .thirdcard {
    height: 22rem;
  }
  .fourthcard,
  .fivethcard,
  .sixthcard {
    height: 11rem;
  }
  .firstcard,
  .thirdcard,
  .fourthcard,
  .sixthcard {
    width: 23rem;
  }
  .secondcard,
  .fivethcard {
    width: 26rem;
  }
}
@media (min-width: 2500px) {
  .firstcard,
  .secondcard,
  .thirdcard {
    height: 28rem;
  }
  .fourthcard,
  .fivethcard,
  .sixthcard {
    height: 14rem;
  }
  .firstcard,
  .thirdcard,
  .fourthcard,
  .sixthcard {
    width: 23rem;
  }
  .secondcard,
  .fivethcard {
    width: 26rem;
  }
}

.card {
  background: transparent !important;
}

/* after filling input box */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  background-color: #021059 !important;
}

/* video csss */
.video-container {
  //background-color: #021059;
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  transition: all 0.6s ease-in-out;
}
.mute_Icon_wrapper {
  position: fixed;
  z-index: 99;
  top: 2%;
  right: 2%;
  width: 2vw;
  height: 2vw;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #0339d9;
  border-radius: 50%;
  cursor: pointer;
}
.video {
  position: fixed;
  object-fit: fill;
  width: 100%;
  height: 100%;
  transform: scale(1);
  margin: auto 0px;
  //transform: translate(20%, 20%);
  z-index: -999;
  opacity: 1;
  top: 0px;
  left: 0px;
  transition: all 0.6s ease-in-out;
}
.notVisible {
  visibility: hidden;
}
.video_Replace_Img {
  object-fit: cover;
  background-color: #000624;
  position: fixed;
  z-index: -9999;
  left: 0px;
  top: 0px;
  width: 100vw;
  height: 100vh;
  transition: background-image 10s ease-in-out;
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
.addBgColor {
  animation: fadeIn 4s 1;
  background-color: #021059;
}
.addLogoSecImage {
  visibility: visible !important;
  animation: fadeIn 4s 1;
  background-size: cover;
  background-position: center center;
  background-image: url(./images/Logo_Page.jpg);
  transition: background-image 10s ease-in-out;
}
.addFormBgImage {
  visibility: visible !important;
  animation: fadeIn 4s 1;
  background-size: cover;
  background-position: center center;
  background-image: url(./images/Ending_Page.jpg);
  transition: background-image 10s ease-in-out;
}

.cntBg {
  transition: background-image 1s ease-in-out;
  //background-image: url(./images/formBg.jpg);
  object-fit: cover;
  position: fixed;
  z-index: -9998;
  left: 0px;
  top: 0px;
  width: 100vw;
  height: 100vh;
}

@keyframes fadeInCntImg {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.cntBG4 {
  opacity: 0;
  /* make things invisible upon start */
  -webkit-animation: fadeInCntImg ease-in 1;
  /* call our keyframe named fadeInCntImg, use animattion ease-in and repeat it only 1 time */
  -moz-animation: fadeInCntImg ease-in 1;
  animation: fadeInCntImg ease-in 1;
  -webkit-animation-fill-mode: forwards;
  /* this makes sure that after animation is done we remain at the last keyframe value (opacity: 1)*/
  -moz-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-duration: 1s;
  -moz-animation-duration: 1s;
  animation-duration: 1s;
}
.cntBG4 {
  -webkit-animation-delay: 1s;
  -moz-animation-delay: 1s;
  animation-delay: 1s;
}

@media screen and (max-width: 600px) {
  .video-container {
    width: 100vw;
    height: 100vh;
    object-fit: fill;
  }
  .video {
    object-fit: cover;
    transform: scale(1.25);
  }
  .mute_Icon_wrapper {
    width: 6vw;
    height: 6vw;
  }
}

/* email svg */
* {
  padding: 0;
  margin: 0;
}
.wrapper {
  transition: all 0.6s;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
}
.checkmark__circle {
  stroke-dasharray: 166;
  stroke-dashoffset: 166;
  stroke-width: 5;
  stroke-miterlimit: 10;
  stroke: #fff;
  fill: none;
  animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
}
.checkmark {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: block;
  stroke-width: 5;
  stroke: #fff;
  stroke-miterlimit: 10;
  margin: 10% auto;
  //box-shadow: inset 0px 0px 0px blue;
  animation: fill 0.4s ease-in-out 0.4s forwards,
    scale 0.3s ease-in-out 0.9s both;
}
.checkmark__check {
  transform-origin: 50% 50%;
  stroke-dasharray: 48;
  stroke-dashoffset: 48;
  animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
}
@keyframes stroke {
  100% {
    stroke-dashoffset: 0;
  }
}
@keyframes scale {
  0%,
  100% {
    transform: none;
  }
  50% {
    transform: scale3d(1.1, 1.1, 1);
  }
}
/* @keyframes fill {
  100% {
    box-shadow: inset 0px 0px 0px 30px blue;
  }
} */

/* 3d card */
/* 
.parallax-card {
  margin: 20px;
  border-radius: 20px;
  transform-style: preserve-3d;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  position: relative;
  min-width: 350px;
  height: 200px;
  cursor: pointer;
}
.parallax-card .parallax-card-container {
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: 20px;
  transition: all 0.2s ease-out;
}
.parallax-card .parallax-card-shadow {
  position: absolute;
  top: 5%;
  left: 5%;
  width: 90%;
  height: 90%;
  transition: all 0.2s ease-out;
  box-shadow: 0 8px 30px rgba(14, 21, 47, 0.6);
  z-index: 0;
}
.parallax-card .parallax-card-layers {
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: 20px;
  overflow: hidden;
  transform-style: preserve-3d;
  z-index: 2;
}
.parallax-card .parallax-card-rendered-layer {
  position: absolute;
  width: 104%;
  height: 104%;
  top: -2%;
  left: -2%;
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  background-size: cover;
  transition: all 0.1s ease-out;
  z-index: 4;
}
.parallax-card .parallax-card-shine {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 20px;
  background: linear-gradient(
    135deg,
    rgba(255, 255, 255, 0.25) 0%,
    rgba(255, 255, 255, 0) 60%
  );
  z-index: 8;
}
.parallax-card:hover {
  z-index: 9;
}
.parallax-card:hover .parallax-card-shadow {
  box-shadow: 0 45px 100px rgba(14, 21, 47, 0.4),
    0 16px 40px rgba(14, 21, 47, 0.4);
}
 */

/* country code phone number styles */
.inputLibraryclass {
  opacity: 1 !important;
  color: #a6aed8 !important;
  outline: none !important;
  border: none !important;
  /* height: 6vh !important; */
}

.react-tel-input .selected-flag::before {
  outline: none !important;
  border: none !important;
  display: none !important;
} /* 
@media (min-width: 1000px) {
  .inputLibraryclass {
    height: 4vh !important;
  }
}

@media (min-width: 1280px) {
  .inputLibraryclass {
    height: 4.6vh !important;
  }
}
@media (min-width: 1366px) {
  .inputLibraryclass {
    height: 6.3vh !important;
  }
}
@media (min-width: 1366px) {
  .inputLibraryclass {
    height: 6.3vh !important;
  }
}
@media (min-width: 1440px) {
  .inputLibraryclass {
    height: 4.8vh !important;
  }
} */

.inputLibraryclass:focus {
  color: #212529 !important;
  background-color: #63cdfe !important;
}
/*contact page submit btn loader */
.btn_wrapper_loader_wrapper {
  position: relative;
}
.submitBtnLoader {
  display: none;
  position: absolute;
  top: 15%;
  left: 41%;

  border: 4px solid rgb(31 13 3 / 80%);
  border-left-color: transparent;
  border-radius: 50%;
}

.submitBtnLoader {
  position: absolute;
  border: 4px solid rgb(31 13 3 / 80%);
  border-left-color: transparent;
  width: 36px;
  height: 36px;
}
.submitBtnLoader {
  position: absolute;
  border: 4px solid rgb(31 13 3 / 80%);
  border-left-color: transparent;
  width: 36px;
  height: 36px;
  animation: spin89345 1s linear infinite;
}

@keyframes spin89345 {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
