@font-face {
  font-family: montReg;
  src: url(../../../fonts/Montserrat/static/Montserrat-Regular.ttf);
}
@font-face {
  font-family: montSemi;
  src: url(../../../fonts/Montserrat/static/Montserrat-SemiBold.ttf);
}

.mainText {
  /*display: none;    /*removed for Trifino*/
  padding-top: 10rem;
  margin-bottom: 3rem;
  width: 100%;
  height: max-content;
  color: #ffffff;
  scroll-margin-top: -10px;
}
.Textwrapper {
  margin: 2rem 0.889rem;
}
.Textwrapper2 {
  margin: 2rem 0.889rem;
}
.Textwrapper3 {
  margin: 2rem 0.889rem;
}

.Textwrapper h1 {
  font-family: montReg;
  /*   font-size: 1.2rem; */

  font-size: 4.6vw;
  text-align: left;
  line-height: 23px;
}

.Textwrapper2 h1 {
  font-family: montReg;
  /*   font-size: 1.2rem; */

  font-size: 4vw;
  text-align: left;
  line-height: 23px;
}
.Textwrapper3 h1 {
  font-family: montReg;
  /*   font-size: 1.2rem; */

  font-size: 4vw;
  text-align: left;
  line-height: 23px;
}

@media (min-width: 430px) {
  .mainText {
    padding-top: 10rem;
    margin-bottom: 3rem;
  }
  .Textwrapper h1 {
    line-height: 35px;
  }
  .Textwrapper2 h1 {
    line-height: 35px;
  }
  .Textwrapper3 h1 {
    line-height: 35px;
  }
}

@media (min-width: 768px) {
  .mainText {
    padding-top: 10rem;
    margin-bottom: 3rem;
  }
  .Textwrapper {
    margin: 3rem 1.489rem;
  }
  .Textwrapper2 {
    margin: 3rem 1.489rem;
  }
  .Textwrapper3 {
    margin: 3rem 1.489rem;
  }

  .Textwrapper h1 {
    padding-left: 0.5rem;
    font-size: 4.6vw;
    text-align: left;
    line-height: 45px;
  }
  .Textwrapper2 h1 {
    padding-left: 0.5rem;
    font-size: 4vw;
    text-align: left;
    line-height: 45px;
  }
  .Textwrapper3 h1 {
    padding-left: 0.5rem;
    font-size: 4vw;
    text-align: left;
    line-height: 45px;
  }
}
@media (min-width: 992px) {
  .mainText {
    padding-top: 7rem;
  }
  .Textwrapper {
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .Textwrapper2 {
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .Textwrapper3 {
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .Textwrapper {
    margin: 0rem 1.489rem;
  }
  .Textwrapper2 {
    margin: 0rem 1.489rem;
  }
  .Textwrapper3 {
    margin: 0rem 1.489rem;
  }

  .Textwrapper h1 {
    font-family: montSemi;
    width: 59vw;
    padding: 0 0rem;
    font-size: 1.2vw;
    text-align: center;
    line-height: 25px;
  }
  
  .Textwrapper2 h1 {
    font-family: montSemi;
    width: 59vw;
    padding: 0 0rem;
    font-size: 1vw; 
    text-align: center;
    line-height: 25px;
  }
  .Textwrapper3 h1 {
    font-family: montSemi;
    width: 59vw;
    padding: 0 0rem;
    font-size: 1vw; 
    text-align: center;
    line-height: 25px;
  }
}

@media (min-width: 1280px) {
  .Textwrapper h1 {
    line-height: 30px;
  }
  .Textwrapper2 h1 {
    line-height: 30px;
  }
  .Textwrapper3 h1 {
    line-height: 30px;
  }
}
@media (min-width: 1700px) {
  .mainText {
    padding-top: 10rem;
  }
  .Textwrapper h1 {
    line-height: 35px;
    margin-bottom: 1rem;
  }
  .Textwrapper2 h1 {
    line-height: 35px;
    margin-bottom: 1rem;
  }
  .Textwrapper3 h1 {
    line-height: 35px;
    margin-bottom: 1rem;
  }
}
